import PropTypes from 'prop-types';

import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
  CssBaseline,
} from '@mui/material';
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import shadows, { customShadows } from './shadows';
import componentsOverride from './overrides';

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

export default function ThemeConfig({ children }) {
  const themeOptions = {
    palette: { ...palette.light, mode: 'light' },
    shape,
    typography,
    breakpoints,
    shadows: shadows.light,
    customShadows: customShadows.light,
  };

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
