import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from "@sentry/react";
import { store, persistor } from './redux/store';
import NotistackProvider from './components/NotistackProvider';

import { SENTRY_DSN } from './config';

import Router from './routes';
import ThemeConfig from './theme';
import './App.css';

export default function App() {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://chat-api.spartez-software.com/chat-widget.js";
    script.async = true;
  
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const queryClient = new QueryClient();
  return (
    <RecoilRoot>
      <ThemeConfig>
        <NotistackProvider>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                  <Router />
                  <chat-widget jira-id={process.env.REACT_APP_JIRA_ID} service-desk-id="1" />
                </QueryClientProvider>
              </BrowserRouter>
            </PersistGate>
          </ReduxProvider>
        </NotistackProvider>
      </ThemeConfig>
    </RecoilRoot>
  );
}
