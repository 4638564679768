import { Box, Button, Typography } from '@mui/material';
import { StopCircle, PlayCircle } from '@mui/icons-material';
import { StopButton } from '../atoms/StopButton';

const AttendanceControls = ({
  isEditPacient,
  handleClick,
  timer,
  seconds,
}: any) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      px: 2,
      pt: 6,
    }}
    gap={1}>
    {isEditPacient ? (
      <StopButton
        variant="contained"
        sx={{ py: 1.5 }}
        size="large"
        onClick={handleClick}
        color="error"
        startIcon={<StopCircle />}>
        Finalizar Atendimento
      </StopButton>
    ) : (
      <Button
        variant="contained"
        sx={{ width: '100%', py: 1.5 }}
        size="large"
        onClick={handleClick}
        startIcon={<PlayCircle />}>
        Iniciar Atendimento
      </Button>
    )}
    {timer > 0 && (
      <Typography variant="body2" color="text.disabled">
        {new Date(seconds * 1000).toISOString().substring(11, 19)}
      </Typography>
    )}
  </Box>
);

export default AttendanceControls;
