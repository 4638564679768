import PropTypes from 'prop-types';
// @mui
import { List, Box } from '@mui/material';
// hooks
import { ListSubheaderStyle, Line } from './style';
import NavList from './NavList';

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({
  navConfig,
  isCollapse,
  ...other
}) {
  const lastIndex = navConfig[0]?.items?.length - 1;

  return (
    <Box {...other}>
      {navConfig.map(group => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}>
            {group.subheader}
          </ListSubheaderStyle>

          {group.items.map((list, index) => (
            <>
              {index === lastIndex && group.items.length !== 1 && <Line />}
              <NavList
                key={list.title}
                data={list}
                depth={1}
                hasChildren={!!list.children}
                isCollapse={isCollapse}
                isLastItem={index === lastIndex}
              />
            </>
          ))}
        </List>
      ))}
    </Box>
  );
}
