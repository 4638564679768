import { createSlice } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
import { dispatch } from '../../store';

const initialState = {
  isLoading: false,
  error: null,
  professionals: [],
};

const slice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getProfessionalsSuccess(state, action) {
      state.isLoading = false;
      state.professionals = action.payload;
    },
  },
});

export default slice.reducer;

export const { selectEvent } = slice.actions;

export const getProfessionals = async () => {
  try {
    dispatch(slice.actions.startLoading());
    // const response = await axios.get('/api/calendar/events');
    // dispatch(slice.actions.getEventsSuccess(response.data.events));
    setTimeout(() => dispatch(slice.actions.getProfessionalsSuccess([])), 2000);
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
