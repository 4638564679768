import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  activeId: 0,
  isEditPacient: false,
  startDate: null,
  hasScheduleLegends: false
};

const slice = createSlice({
  name: 'navBar',
  initialState,
  reducers: {
    setOpen(state, action) {
      state.open = action.payload;
    },

    setActive(state, action) {
      state.activeId = action.payload;
    },

    setIsEditPacient(state, action) {
      state.isEditPacient = action.payload;
    },

    setHasScheduleLegends(state, action) {
      state.hasScheduleLegends = action.payload;
    },

    setStartDate(state, action) {
      state.startDate = action.payload;
    },
  },
});

export default slice.reducer;

export const { setOpen, setActive, setIsEditPacient, setStartDate, setHasScheduleLegends } =
  slice.actions;
