// scroll bar
import 'simplebar/dist/simplebar.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import MemedProvider from 'reufy-memed-react';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <MemedProvider scriptSrc="https://partners.memed.com.br/integration.js" scriptId='memedScript'>
      <App />
    </MemedProvider>
  </HelmetProvider>
);
