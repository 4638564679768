import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';

const initialState = {
  selectedCharge: null,
  isLoading: false,
  error: null,
  charges: [],
};

export const createPayment = createAsyncThunk(
  'reufy-pay/createPayment',
  async charge => {
    const response = await axios.post(`/reufy-pay/payment`, charge);
    return response.data;
  }
);

export const fetchProfessionalHasSplit = createAsyncThunk(
  'reufy-pay/professionalHasSplit',
  async ({ professionalId, clinicId }) => {
    const response = await axios.get(
      `/reufy-pay/professional?professionalId=${professionalId}&clinicId=${clinicId}`
    );
    return response.data;
  }
);

export const reufyPaySlice = createSlice({
  name: 'reufyPay',
  initialState,
  reducers: {
    selectCharge: (state, action) => {
      state.selectedCharge = action.payload;
    },
    clearSelectedCharge: state => {
      state.selectedCharge = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createPayment.pending, state => {
        state.isLoading = true;
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { selectCharge, clearSelectedCharge } = reufyPaySlice.actions;
export default reufyPaySlice.reducer;
