import axios from 'axios';
import { HOST_API } from '../config';

const accessToken = window.localStorage.getItem('@reufy-accessToken');

const headers = () => {
  if (accessToken) {
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }
};

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: headers(),
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response;

    if (status === 401 && error.config.url !== '/auth/login') {
      window.localStorage.removeItem('@reufy-accessToken');
      window.localStorage.removeItem('@reufyUser');
      window.location.href = '/login';

      return Promise.reject(error);
    }

    return Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    );
  }
);


export default axiosInstance;

