import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import axios from '../../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  contracts: {
    contracts: [],
    meta: {}
  },
  selectedContract: null
};

export const fetchContracts = createAsyncThunk('contracts/fetchContracts', async (f) => {
  const filters = {};
  Object.entries(f).forEach(([key, value]) => {
    if (value) {
      filters[key] = value;
    }
  });
  const response = await axios.get(`/contracts`, { params: filters });
  return response.data;
});

export const exportCsv = createAsyncThunk('contracts/exportCsv', async (f) => {
  const filters = {};
  Object.entries(f).forEach(([key, value]) => {
    if (value) {
      filters[key] = value;
    }
  });
  const response = await axios.get(`/contracts/export/csv`, { params: filters });
  return response.data;
});

export const saveContract = createAsyncThunk('contracts/saveContract', async (contract) => {
  const response = await axios.post(`/contracts`, contract);
  return response.data;
});

export const updateContract = createAsyncThunk('contracts/updateContract', async (contract) => {
  const response = await axios.put(`/contracts/${contract?._id}`, contract);
  return response.data;
});

export const cancelContract = createAsyncThunk('contracts/cancelContract', async ({ contractId, cancelData }) => {
  const response = await axios.put(`/contracts/${contractId}/cancel`, cancelData);
  return response.data;
});

export const getCepViaCep = async (cep) => {
  cep = cep.replace('-', '');
  const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
  return response.data;
};

export const getContactorByDoc = async (cpfCnpj) => {
  cpfCnpj = cpfCnpj.replace(/[^\d]+/g, '');
  const response = await axios.get(`/contracts/cpfcnpj/${cpfCnpj}`);
  return response.data;
};

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    selectContract: (state, action) => {
      state.selectedContract = action.payload;
    },
    clearSelectedContract: (state) => {
      state.selectedContract = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContracts.pending, (state) => {
        state.loading = true;
      })
      .addCase(exportCsv.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveContract.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateContract.pending, (state) => {
        state.loading = true;
      })
      .addCase(cancelContract.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchContracts.fulfilled, (state, action) => {
        state.contracts = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(exportCsv.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const csv = action.payload;
        if(csv) {
          const blob = new Blob([`${csv}`], {
            type: 'text/csv;charset=utf-8',
          });
          saveAs(blob, 'reufy_fornecedores.csv');
        }
      })
      .addCase(saveContract.fulfilled, (state, action) => {
        state.contracts.items.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateContract.fulfilled, (state, action) => {
        const index = state.contracts.items.findIndex(contract => contract._id === action.payload._id);
        if (index !== -1) {
          state.contracts.items[index] = action.payload;
        }
      })
      .addCase(cancelContract.fulfilled, (state, action) => {
        const index = state.contracts.items.findIndex(contract => contract._id === action.payload._id);
        if (index !== -1) {
          state.contracts.items[index] = action.payload;
        }
      })
      .addCase(fetchContracts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(exportCsv.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(saveContract.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateContract.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(cancelContract.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { selectContract, clearSelectedContract } = contractsSlice.actions;
export default contractsSlice.reducer;