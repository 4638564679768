import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../../redux/store';
import axios from '../../../utils/axios';

const initialState = {
  isLoading: false,
  csvValidated: undefined,
  csvUploaded: undefined,
  dataMigrationLogs: undefined,
  error: null,
};

const dataMigrationSlice = createSlice({
  name: 'dataMigrationSlice',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    uploadCSVSuccess(state, action) {
      state.isLoading = false;
      state.csvUploaded = action.payload;
    },

    validateCSVSuccess(state, action) {
      state.isLoading = false;
      state.csvValidated = action.payload;
    },

    dataMigrationLogsSuccess(state, action) {
      state.isLoading = false;
      state.dataMigrationLogs = action.payload;
    },
  },
});

export default dataMigrationSlice;

export const { uploadCSVSuccess, validateCSVSuccess } =
  dataMigrationSlice.actions;

export const uploadCSV = async (files, user) => {
  try {
    dispatch(dataMigrationSlice.actions.startLoading());
    // eslint-disable-next-line prefer-const
    let formData = new FormData();
    files.forEach(file => {
      formData.append('file', file);
    });
    formData.append('user', JSON.stringify(user));
    const response = await axios.post('/clinic/upload-csv', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    dispatch(dataMigrationSlice.actions.uploadCSVSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(dataMigrationSlice.actions.hasError(error));
  }
};

export const getDataMigrationLogs = async clinicId => {
  try {
    dispatch(dataMigrationSlice.actions.startLoading());
    const response = await axios.get(`/clinic/data-migration-logs/${clinicId}`);
    dispatch(dataMigrationSlice.actions.dataMigrationLogsSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(dataMigrationSlice.actions.hasError(error));
  }
};

export const validateCSV = async files => {
  try {
    dispatch(dataMigrationSlice.actions.startLoading());
    // eslint-disable-next-line prefer-const
    let formData = new FormData();
    files.forEach((file, index) => {
      formData.append('file', file);
    });
    const response = await axios.post('/clinic/validate-csv', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    dispatch(dataMigrationSlice.actions.validateCSVSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(dataMigrationSlice.actions.hasError(error));
  }
};
