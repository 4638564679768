import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../../redux/store';
import axios from '../../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  professionals: [],
  user: null,
};

const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      console.error('has error: ', action.payload);
      state.isLoading = false;
      state.error = action.payload;
    },

    loginSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },
  },
});

export default loginSlice;

export const { selectEvent, setSupportToken } = loginSlice.actions;

export const login = async data => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const response = await axios.post('/auth/login', data);
    window.localStorage.setItem(
      '@reufy-accessToken',
      response.data.access_token
    );
    axios.defaults.headers.common = {
      Authorization: `Bearer ${response.data.access_token}`,
    };
    window.localStorage.setItem(
      '@reufyUser',
      JSON.stringify(response.data.user)
    );
    window.localStorage.setItem(
      '@reufySupportToken',
      response.data.support_token
    );

    dispatch(loginSlice.actions.loginSuccess(response.data.user));
    dispatch(loginSlice.actions.stopLoading());
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));
    throw error;
  }
};

export const logout = async () => {
  try {
    dispatch(loginSlice.actions.stopLoading());
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));
  }
};
