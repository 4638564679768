export const scheduleStatus = [
    {
      id: 1,
      name: 'scheduled',
      color: '#637381',
      backgroundColor: '#F9FAFB',
      description: 'Agendado',
      icon: 'mdi:calendar-blank',
    },
    {
      id: 2,
      name: 'confirmed',
      color: '#0C53B7',
      backgroundColor: '#D0F2FF',
      description: 'Confirmado',
      icon: 'mdi:calendar-check',
    },
    {
      id: 3,
      name: 'awaiting',
      color: '#B78103',
      backgroundColor: '#FFF7CD',
      description: 'Aguardando Atendimento',
      icon: 'ic:outline-access-time',
    },
    {
      id: 4,
      name: 'canceled',
      color: '#B72136',
      backgroundColor: '#FFE7D9',
      description: 'Não Compareceu',
      icon: 'mdi:calendar-remove',
    },
    {
      id: 5,
      name: 'finished',
      color: '#229A16',
      backgroundColor: '#A3FFE3',
      description: 'Paciente Atendido',
      icon: 'mdi:calendar-check',
    },
  ];