import { Box } from '@mui/material';

export const RoundBox = ({
  color,
  borderColor,
}: {
  color: string;
  borderColor: string;
}) => (
  <Box
    sx={{
      width: 16,
      height: 16,
      backgroundColor: color,
      borderRadius: '50%',
      border: `1px solid ${borderColor}`,
    }}
  />
);
