import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../../redux/store';
import axiosInstance from '../../../utils/axios';

const initialState = {
  isLoading: false,
  emailStatus: null,
};

const recoverPasswordSlice = createSlice({
  name: 'recoverPasswordSlice',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },

    hasEmail(state, action) {
      state.isLoading = false;
      state.emailStatus = action.payload;
    },

    recoverPasswordSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },
  },
});

export default recoverPasswordSlice;

export const recoverPasswordInvited = async email => {
  try {
    dispatch(recoverPasswordSlice.actions.startLoading());
    const emailResponse = await axiosInstance.get(
      `/auth/recover-password/${email}`,
      email
    );
    dispatch(recoverPasswordSlice.actions.stopLoading());
    return emailResponse.data;
  } catch (error) {
    dispatch(recoverPasswordSlice.actions.stopLoading());
  }
};

export const saveNewPasswordSlice = async (email, token, password) => {
  try {
    dispatch(recoverPasswordSlice.actions.startLoading());
    const emailResponse = await axiosInstance.post(`/auth/resetPassword`, {
      email,
      token,
      password,
    });
    dispatch(recoverPasswordSlice.actions.stopLoading());
    return emailResponse.data;
  } catch (error) {
    dispatch(recoverPasswordSlice.actions.stopLoading());
  }
};
