import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  scheduledResources: {
    items: [],
    meta: {},
  },
  selectedScheduledResource: null,
};

export const fetchScheduledResources = createAsyncThunk(
  'scheduledResources/fetchScheduledResources',
  async filters => {
    const response = await axios.get(`/resource-scheduling`, {
      params: filters,
    });
    return response.data;
  }
);

export const saveScheduledResource = createAsyncThunk(
  'scheduledResources/saveScheduledResource',
  async scheduledResource => {
    const response = await axios.post(
      `/resource-scheduling`,
      scheduledResource
    );
    return response.data;
  }
);

export const updateScheduledResource = createAsyncThunk(
  'scheduledResources/updateScheduledResource',
  async scheduledResource => {
    const response = await axios.put(
      `/resource-scheduling/${scheduledResource?._id}`,
      scheduledResource
    );
    return response.data;
  }
);

export const deleteScheduledResource = createAsyncThunk(
  'scheduledResources/deleteScheduledResource',
  async id => {
    const response = await axios.delete(`/resource-scheduling/${id}`);
    return { id, deleted: response.data.deleted };
  }
);

export const deleteRecurrence = createAsyncThunk(
  'scheduledResources/deleteRecurrence',
  async ({ id, value }) => {
    const response = await axios.delete(`/resource-scheduling/${id}/${value}`);
    return { id, deleted: response.data.deleted };
  }
);

export const scheduledResourceSlice = createSlice({
  name: 'scheduledResources',
  initialState,
  reducers: {
    selectScheduledResource: (state, action) => {
      state.selectedScheduledResource = action.payload;
    },
    clearSelectedScheduledResource: state => {
      state.selectedScheduledResource = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchScheduledResources.pending, state => {
        state.loading = true;
      })

      .addCase(saveScheduledResource.pending, state => {
        state.loading = true;
      })
      .addCase(updateScheduledResource.pending, state => {
        state.loading = true;
      })
      .addCase(deleteScheduledResource.pending, state => {
        state.loading = true;
      })
      .addCase(fetchScheduledResources.fulfilled, (state, action) => {
        state.scheduledResources.items = action.payload;
        state.isLoading = false;
        state.error = null;
      })

      .addCase(saveScheduledResource.fulfilled, (state, action) => {
        state.scheduledResources.items.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateScheduledResource.fulfilled, (state, action) => {
        const index = state.scheduledResources.items.findIndex(
          scheduledResource => scheduledResource._id === action.payload._id
        );
        if (index !== -1) {
          state.scheduledResources.items[index] = action.payload;
        }
      })
      .addCase(deleteScheduledResource.fulfilled, (state, action) => {
        if (action.payload.deleted === true) {
          const updatedItems = state.scheduledResources.items.filter(
            item => item._id !== action.payload.id
          );
          state.scheduledResources.items = updatedItems;
        }
      })
      .addCase(fetchScheduledResources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(saveScheduledResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateScheduledResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteScheduledResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { selectScheduledResource, clearSelectedScheduledResource } =
  scheduledResourceSlice.actions;
export default scheduledResourceSlice.reducer;
