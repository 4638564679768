import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import axios from '../../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  financial: [],
  patientPayments: [],
  cashFlow: [],
  medicalRecords: [],
  attendance: [],
  selectedResourceCategory: null,
  isLoadingExport: false,
  CIDs: [],
  demographic: {
    age: [],
    professional: [],
    shift: [],
    weekDay: [],
    insurance: [],
    gender: [],
    status: [],
  },
};

export const fetchFinancialReport = createAsyncThunk(
  'report/fetchFinancialReport',
  async f => {
    const filters = {};
    Object.entries(f).forEach(([key, value]) => {
      if (value && value !== undefined && value !== null) {
        if (Array.isArray(value) && value.length > 0) {
          filters[key] = `${value.join(',')}`;
        } else {
          filters[key] = value;
        }
      }
    });
    const response = await axios.get(`/finance/transaction/report/financial`, {
      params: filters,
    });
    return response.data;
  }
);

export const fetchCashFlowReport = createAsyncThunk(
  'report/fetchCashFlowReport',
  async ({ filters, timeInterval }) => {
    const filterValues = {};

    const period =
      timeInterval === 'daily' || timeInterval === 'weekly' ? 'week' : 'month';
    Object.entries(filters).forEach(([key, value]) => {
      if (value && value !== undefined && value !== null) {
        if (Array.isArray(value) && value.length > 0) {
          filterValues[key] = `${value.join(',')}`;
        } else {
          filterValues[key] = value;
        }
      }
    });
    const response = await axios.get(`/finance/transaction/report/${period}`, {
      params: filterValues,
    });
    return response.data;
  }
);

export const fetchAttendanceReport = createAsyncThunk(
  'report/fetchAttendanceReport',
  async f => {
    const filters = {};
    Object.entries(f).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value) && value.length > 0) {
          filters[key] = `${value.join(',')}`;
        } else {
          filters[key] = value;
        }
      }
    });
    const response = await axios.get(`/attendance/report`, {
      params: filters,
    });
    return response.data;
  }
);

export const fetchDemogragraphicReport = createAsyncThunk(
  'report/fetchDemogragraphicReport',
  async f => {
    const filters = {};
    Object.entries(f).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          filters[key] = `${value.join(',')}`;
        } else {
          filters[key] = value;
        }
      }
    });
    const response = await axios.get(`/attendance/demographic`, {
      params: filters,
    });
    return response.data;
  }
);

export const fetchPatientPaymentReport = createAsyncThunk(
  'report/fetchPatientPaymentReport',
  async f => {
    const filters = {};
    Object.entries(f).forEach(([key, value]) => {
      if (value) {
        filters[key] = value;
      }
    });
    const response = await axios.get(`/finance/transaction/patient/payments`, {
      params: filters,
    });
    return response.data;
  }
);

export const fetchMedicalRecordReport = createAsyncThunk(
  'report/fetchMedicalRecordReport',
  async f => {
    const filters = {};
    Object.entries(f).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value) && value.length > 0) {
          filters[key] = `${value.join(',')}`;
        } else {
          filters[key] = value;
        }
      }
    });
    const response = await axios.get(`/attendance/unevolved`, {
      params: filters,
    });
    return response.data;
  }
);

export const getCIDByQuery = createAsyncThunk(
  'report/getCIDByQuery',
  async query => {
    const response = await axios.get(`/cid/${query}`);
    return response.data;
  }
);

export const getDemographicByAge = createAsyncThunk(
  'report/demographicByAge',
  async f => {
    const filters = {};
    Object.entries(f).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          filters[key] = `${value.join(',')}`;
        } else {
          filters[key] = value;
        }
      }
    });
    filters.report = 'ageRange';
    const response = await axios.get(`/attendance/demographic`, {
      params: filters,
    });
    return response.data;
  }
);

export const getDemographicByProfessional = createAsyncThunk(
  'report/demographicByProfessional',
  async f => {
    const filters = {};
    Object.entries(f).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          filters[key] = `${value.join(',')}`;
        } else {
          filters[key] = value;
        }
      }
    });
    filters.report = 'typeProfessional';
    const response = await axios.get(`/attendance/demographic`, {
      params: filters,
    });
    return response.data;
  }
);

export const getDemographicByShift = createAsyncThunk(
  'report/demographicByShift',
  async f => {
    const filters = {};
    Object.entries(f).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          filters[key] = `${value.join(',')}`;
        } else {
          filters[key] = value;
        }
      }
    });
    filters.report = 'shift';
    const response = await axios.get(`/attendance/demographic`, {
      params: filters,
    });
    return response.data;
  }
);

export const getDemographicByWeekDay = createAsyncThunk(
  'report/demographicByWeekDay',
  async f => {
    const filters = {};
    Object.entries(f).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          filters[key] = `${value.join(',')}`;
        } else {
          filters[key] = value;
        }
      }
    });
    filters.report = 'daysWeek';
    const response = await axios.get(`/attendance/demographic`, {
      params: filters,
    });
    return response.data;
  }
);

export const getDemographicByInsurance = createAsyncThunk(
  'report/demographicByInsurance',
  async f => {
    const filters = {};
    Object.entries(f).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          filters[key] = `${value.join(',')}`;
        } else {
          filters[key] = value;
        }
      }
    });
    filters.report = 'healthInsurance';
    const response = await axios.get(`/attendance/demographic`, {
      params: filters,
    });
    return response.data;
  }
);

export const getDemographicByGender = createAsyncThunk(
  'report/demographicByGender',
  async f => {
    const filters = {};
    Object.entries(f).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          filters[key] = `${value.join(',')}`;
        } else {
          filters[key] = value;
        }
      }
    });
    filters.report = 'gender';
    const response = await axios.get(`/attendance/demographic`, {
      params: filters,
    });
    return response.data;
  }
);

export const getDemographicByStatus = createAsyncThunk(
  'report/demographicByStatus',
  async f => {
    const filters = {};
    Object.entries(f).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          filters[key] = `${value.join(',')}`;
        } else {
          filters[key] = value;
        }
      }
    });
    filters.status = 'finished';
    const response = await axios.get(`/schedule/status`, {
      params: filters,
    });
    return response.data;
  }
);

export const getDemographicCsv = createAsyncThunk(
  'report/demographicCsv',
  async f => {
    const filters = {};
    Object.entries(f).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          filters[key] = `${value.join(',')}`;
        } else {
          filters[key] = value;
        }
      }
    });
    filters.export = 'csv';
    const response = await axios.get(`/attendance/demographic`, {
      params: filters,
    });
    return response.data;
  }
);

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    clearReport: (state, action) => {
      const reportToClear = action.payload;
      state[reportToClear] = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchFinancialReport.pending, (state, action) => {
        const filters = action.meta?.arg;
        if (filters.export) {
          state.isLoadingExport = true;
        } else {
          state.isLoading = true;
        }
      })
      .addCase(fetchPatientPaymentReport.pending, (state, action) => {
        const filters = action.meta?.arg;
        if (filters.export) {
          state.isLoadingExport = true;
        } else {
          state.isLoading = true;
        }
      })
      .addCase(fetchCashFlowReport.pending, (state, action) => {
        const filters = action.meta?.arg?.filters;
        if (filters.export) {
          state.isLoadingExport = true;
        } else {
          state.isLoading = true;
        }
      })
      .addCase(fetchMedicalRecordReport.pending, (state, action) => {
        const filters = action.meta?.arg;
        if (filters.export) {
          state.isLoadingExport = true;
        } else {
          state.isLoading = true;
        }
      })
      .addCase(fetchAttendanceReport.pending, (state, action) => {
        const filters = action.meta?.arg;
        if (filters.export) {
          state.isLoadingExport = true;
        } else {
          state.isLoading = true;
        }
      })
      .addCase(getCIDByQuery.pending, state => {
        state.loading = true;
      })
      .addCase(getDemographicByAge.pending, state => {
        state.loading = true;
      })
      .addCase(getDemographicByProfessional.pending, state => {
        state.loading = true;
      })
      .addCase(getDemographicByShift.pending, state => {
        state.loading = true;
      })
      .addCase(getDemographicByWeekDay.pending, state => {
        state.loading = true;
      })
      .addCase(getDemographicByInsurance.pending, state => {
        state.loading = true;
      })
      .addCase(getDemographicByGender.pending, state => {
        state.loading = true;
      })
      .addCase(getDemographicByStatus.pending, state => {
        state.loading = true;
      })
      .addCase(getDemographicCsv.pending, state => {
        state.loading = true;
      })
      .addCase(fetchFinancialReport.fulfilled, (state, action) => {
        const payload = action.payload;
        const filters = action.meta?.arg;

        if (filters && filters.export === 'csv') {
          const csv = payload;
          const blob = new Blob([csv], {
            type: 'text/csv;charset=utf-8',
          });
          saveAs(blob, 'reufy_financeiro.csv');
          state.isLoadingExport = false;
        } else {
          state.financial = payload;
          state.isLoading = false;
        }

        state.error = null;
      })
      .addCase(fetchPatientPaymentReport.fulfilled, (state, action) => {
        const payload = action.payload;
        const filters = action.meta.arg;

        if (filters && filters.export === 'csv') {
          const csv = payload;
          const blob = new Blob([csv], {
            type: 'text/csv;charset=utf-8',
          });
          saveAs(blob, 'reufy_pagamentos_paciente.csv');
          state.isLoadingExport = false;
        } else {
          state.patientPayments = payload;
          state.isLoading = false;
        }

        state.error = null;
      })
      .addCase(fetchCashFlowReport.fulfilled, (state, action) => {
        const payload = action.payload;
        const filters = action.meta.arg.filters;

        if (filters && filters.export === 'csv') {
          const csv = payload;
          const blob = new Blob([csv], {
            type: 'text/csv;charset=utf-8',
          });
          saveAs(blob, 'reufy_fluxo_de_caixa.csv');
          state.isLoadingExport = false;
        } else {
          state.cashFlow = payload;
          state.isLoading = false;
        }

        state.error = null;
      })
      .addCase(fetchMedicalRecordReport.fulfilled, (state, action) => {
        const payload = action.payload;
        const filters = action.meta.arg;

        if (filters && filters.export === 'csv') {
          const csv = payload;
          const blob = new Blob([csv], {
            type: 'text/csv;charset=utf-8',
          });
          saveAs(blob, 'reufy_prontuarios.csv');
          state.isLoadingExport = false;
        } else {
          state.medicalRecords = payload;
          state.isLoading = false;
        }

        state.error = null;
      })
      .addCase(fetchAttendanceReport.fulfilled, (state, action) => {
        const payload = action.payload;
        const filters = action.meta.arg;

        if (filters && filters.export === 'csv') {
          const csv = payload;
          const blob = new Blob([csv], {
            type: 'text/csv;charset=utf-8',
          });
          saveAs(blob, 'reufy_atendimentos.csv');
          state.isLoadingExport = false;
        } else {
          state.attendance = payload;
          state.isLoading = false;
        }

        state.error = null;
      })
      .addCase(getCIDByQuery.fulfilled, (state, action) => {
        state.CIDs = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getDemographicByAge.fulfilled, (state, action) => {
        state.demographic.age = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getDemographicByProfessional.fulfilled, (state, action) => {
        state.demographic.professional = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getDemographicByShift.fulfilled, (state, action) => {
        state.demographic.shift = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getDemographicByWeekDay.fulfilled, (state, action) => {
        state.demographic.weekDay = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getDemographicByInsurance.fulfilled, (state, action) => {
        state.demographic.insurance = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getDemographicByGender.fulfilled, (state, action) => {
        state.demographic.gender = action.payload;
        state.loading = false;
        state.error = null;
      })

      .addCase(getDemographicByStatus.fulfilled, (state, action) => {
        state.demographic.status = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getDemographicCsv.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const csv = action.payload;
        if (csv) {
          const blob = new Blob([`${csv}`], {
            type: 'text/csv;charset=utf-8',
          });
          saveAs(blob, 'reufy_sociodemografico.csv');
        }
      })
      .addCase(fetchFinancialReport.rejected, (state, action) => {
        state.loading = false;
        state.isLoadingExport = false;
        state.error = action.error.message;
      })
      .addCase(fetchPatientPaymentReport.rejected, (state, action) => {
        state.loading = false;
        state.isLoadingExport = false;
        state.error = action.error.message;
      })
      .addCase(fetchCashFlowReport.rejected, (state, action) => {
        state.loading = false;
        state.isLoadingExport = false;
        state.error = action.error.message;
      })
      .addCase(fetchMedicalRecordReport.rejected, (state, action) => {
        state.loading = false;
        state.isLoadingExport = false;
        state.error = action.error.message;
      })
      .addCase(fetchAttendanceReport.rejected, (state, action) => {
        state.loading = false;
        state.isLoadingExport = false;
        state.error = action.error.message;
      })
      .addCase(getCIDByQuery.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDemographicByAge.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDemographicByProfessional.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDemographicByShift.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDemographicByWeekDay.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDemographicByInsurance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDemographicByGender.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDemographicByStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDemographicCsv.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const { clearReport } = reportSlice.actions;

export default reportSlice.reducer;
