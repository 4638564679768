import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { List, Collapse, Link } from '@mui/material';
import NavItem from './NavItem';
import { useDispatch, useSelector } from '../../../redux/store';
import { setActive } from '../../../redux/slices/navBar';

// ----------------------------------------------------------------------

NavList.propTypes = {
  data: PropTypes.object,
  depth: PropTypes.number,
  hasChildren: PropTypes.bool,
  isCollapse: PropTypes.bool,
  isLastItem: PropTypes.bool,
};

export default function NavList({
  data,
  depth,
  hasChildren,
  isCollapse = false,
  isLastItem = false,
}) {
  const dispatch = useDispatch();
  const { activeId } = useSelector(state => state.navBar);

  const active = data.id === activeId || data.subId?.includes(activeId);

  const [open, setOpen] = useState(active);

  const handleClickItem = () => {
    if (!hasChildren) {
      dispatch(setActive(data.id));
    }
    setOpen(!open);
  };

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={open}
        active={active}
        isCollapse={isCollapse}
        onClick={handleClickItem}
        isLastItem={isLastItem}
      />

      {!isCollapse && hasChildren && (
        <Collapse in={open} unmountOnExit>
          <List component="div" disablePadding>
            <NavSubList data={data.children} depth={depth} />
          </List>
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

NavSubList.propTypes = {
  data: PropTypes.array,
  depth: PropTypes.number,
};

function NavSubList({ data, depth }) {
  return (
    <>
      {data.map(list => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChildren={!!list.children}
        />
      ))}
    </>
  );
}
