import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'white',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      p={5}>
      <Box
        component="img"
        src="/assets/logo/logo-full.png"
        sx={{ height: 40 }}
      />
      <Typography variant="caption" mt={1}>
        © Todos Direitos Reservados
      </Typography>
    </Box>
  );
};

export default Footer;
