import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export default function AuthGuard({ children }) {
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  const reufyUser = window.localStorage.getItem('@reufyUser');

  const isAuthenticated = !!reufyUser && JSON.parse(reufyUser).verified;

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to="/login" />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
