import { StatusCodes } from 'http-status-codes';
import { createSlice } from '@reduxjs/toolkit';
import { setActive } from '../../../redux/slices/navBar';
import { dispatch } from '../../../redux/store';
import axios from '../../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  errorHealthinsurance: null,
  professionals: [],
  clinic: null,
  verify: null,
  health: [],
  healths: null,
  currentAccounts: [],
  costCenters: [],
  financeCategories: [],
  selectedHealth: null,
  paymentMethods: null,
  transferRules: null,
  procedures: [],
  saveProId: null,
  isOnline: null,
  cep: null,
  hours: null,
};

const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    hasErrorHealthInsurance(state, action) {
      state.errorHealthinsurance = action.payload;
      state.isLoading = false;
    },

    getClinicSuccess(state, action) {
      state.clinic = action.payload;
      state.isLoading = false;
    },

    getHealthSuccess(state, action) {
      state.healths = action.payload;
      state.isLoading = false;
    },
    getHealthByIdSuccess(state, action) {
      state.selectedHealth = action.payload;
      state.isLoading = false;
    },
    cleanHealthSuccess(state, action) {
      state.professionals = [];
      state.selectedHealth = null;
      state.isLoading = false;
    },
    editClinicSuccess(state, action) {
      state.clinic = action.payload;
      state.isLoading = false;
    },
    editClinicOnlineSuccess(state, action) {
      state.isOnline = action.payload;
      state.isLoading = false;
    },
    verifySuccess(state, action) {
      state.verify = action.payload;
      state.isLoading = false;
    },
    professionalSuccess(state, action) {
      const aux = [...state.professionals];
      aux.push(action.payload);
      state.professional = aux;
      state.isLoading = false;
      state.clinic.professionals = state.clinic.professionals.filter(
        prof => prof.id !== action.payload.id
      );
    },
    allProfessionalSuccess(state, action) {
      state.professionals = action.payload;
      state.isLoading = false;
    },
    healthSuccess(state, action) {
      state.health = action.payload;
      state.isLoading = false;
    },

    currentAccountSuccess(state, action) {
      state.currentAccounts = action.payload;
      state.isLoading = false;
    },

    costCentersSuccess(state, action) {
      state.costCenters = action.payload;
      state.isLoading = false;
    },

    financeCategoriesSuccess(state, action) {
      state.financeCategories = action.payload;
      state.isLoading = false;
    },

    paymentMethodsSuccess(state, action) {
      state.paymentMethods = action.payload;
      state.isLoading = false;
    },

    transferRulesSuccess(state, action) {
      state.transferRules = action.payload;
      state.isLoading = false;
    },

    proceduresSuccess(state, action) {
      state.procedures = action.payload;
    },

    saveProids(state, action) {
      state.saveProId = action.payload;
      state.isLoading = false;
    },
    cepSuccess(state, action) {
      state.cep = action.payload;
      state.isLoading = false;
    },
    editHoursSuccess(state, action) {
      state.hours = action.payload;
      state.isLoading = false;
    },
  },
});

export default loginSlice;

export const { professionalSuccess, selectEvent } = loginSlice.actions;

export const getMyClinic = async data => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const response = await axios.get(`/clinic/${data.clinic}`);
    dispatch(loginSlice.actions.getClinicSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));
  }
};

export const getClinicWithUser = async () => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const userJson = window.localStorage.getItem('@reufyUser');
    const user = JSON.parse(userJson);
    const response = await axios.get(`/clinic/${user.clinic}`);
    dispatch(loginSlice.actions.getClinicSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));
  }
};

export const getHealthClinic = async data => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const response = await axios.get(
      `/clinic/health-insurance/clinic/${data.clinic}`
    );
    dispatch(loginSlice.actions.getHealthSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));
    dispatch(loginSlice.actions.cleanHealthSuccess());
    return { error: 'Não foi possível buscas os convênios!' };
  }
};

export const getCepViaCep = async cep => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
    dispatch(loginSlice.actions.cepSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));
    dispatch(loginSlice.actions.cleanHealthSuccess());
  }
};

export const cleanHealth = async () => {
  try {
    dispatch(loginSlice.actions.startLoading());
    dispatch(loginSlice.actions.cleanHealthSuccess());
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));
  }
};

export const getHealthById = async data => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const response = await axios.get(`/clinic/health-insurance/${data}`);
    // const response = await axios.get(`/health-insurance/clinic/${data}`);
    dispatch(loginSlice.actions.getHealthByIdSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));
  }
};

export const toggleHealthinsuranceClinicStatus = async healthinsuranceId => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const response = await axios.patch(`/clinic/health-insurance/${healthinsuranceId}/status`);
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));
  }
};

export const editClinic = async (data, clinicId) => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const response = await axios.put(`/clinic/${clinicId}`, data);
    dispatch(loginSlice.actions.getClinicSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));
  }
};

export const putClinic = async data => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const response = await axios.put(`/clinic/${data.clinic}`, data.body);
    // window.localStorage.setItem('@reufy-accessToken', response.data.access_token);
    // window.localStorage.setItem('@reufyUser', JSON.stringify(response.data.user))
    dispatch(loginSlice.actions.getClinicSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));
  }
};

export const editOnlineInfo = async (data, clinicId) => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const response = await axios.post(
      `/clinic/${clinicId}/update/online-info`,
      data
    );
    dispatch(loginSlice.actions.getClinicSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));
    return { error: "Não foi possível atualizar as informações!" };
  }
};

export const editOnlineSchedule = async (clinicId, enable) => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const response = await axios.post(
      `/clinic/${clinicId}/active-inactive/online/${enable}`
    );
    dispatch(loginSlice.actions.editClinicOnlineSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));
  }
};

export const verifyUrl = async data => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const response = await axios.get(`/clinic/slug/${data}`);
    dispatch(loginSlice.actions.verifySuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));
  }
};

export const editHours = async (clinic, hours) => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const response = await axios.put(`/clinic/office-hour/${clinic}`, hours);
    dispatch(loginSlice.actions.editHoursSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));
    if (error) {
      return { error: "Não foi possível atualizar o horário de funcionamento" };
    }
  }
};

export const addProfessional = async (data, clinicId) => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const response = await axios.post(
      `/clinic/${clinicId}/register/professional`,
      data
    );
    // window.localStorage.setItem('@reufy-accessToken', response.data.access_token);
    // window.localStorage.setItem('@reufyUser', JSON.stringify(response.data.user))
    dispatch(loginSlice.actions.professionalSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));
  }
};

export const editProfessionalById = async (data, clinicId, proId) => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const response = await axios.put(
      `/clinic/${clinicId}/update/professional/${proId}`,
      data
    );
    dispatch(loginSlice.actions.professionalSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));

    if (error?.statusCode === StatusCodes.FORBIDDEN) {
      return { error: error.message };
    }
  }
};

export const deleteProfessional = async (professionalId, clinicId) => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const response = await axios.delete(`/professional/${professionalId}/${clinicId}`);
    dispatch(loginSlice.actions.professionalSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));

    if (error?.statusCode === StatusCodes.FORBIDDEN) {
      return { error: error.message };
    }
  }
};

export const helperSavePro = async data => {
  try {
    dispatch(loginSlice.actions.saveProids(data));
    return data;
  } catch (error) {
    dispatch(loginSlice.actions.hasError(error));
  }
};

export const addHealthInsurance = async (data, clinicId) => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const response = await axios.post(`/clinic/health-insurance`, data);
    dispatch(loginSlice.actions.healthSuccess(response.data));
    dispatch(loginSlice.actions.hasErrorHealthInsurance(null));
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasErrorHealthInsurance(error));
    dispatch(loginSlice.actions.hasError(error));
    return error;
  }
};

export const editHealthInsurance = async (data, id) => {
  try {
    dispatch(loginSlice.actions.startLoading());
    const response = await axios.put(`/clinic/health-insurance/${id}`, data);
    dispatch(loginSlice.actions.healthSuccess(response.data));
    dispatch(loginSlice.actions.hasErrorHealthInsurance(null));
    return response.data;
  } catch (error) {
    dispatch(loginSlice.actions.hasErrorHealthInsurance(error));
    dispatch(loginSlice.actions.hasError(error));
    return error;
  }
};

export function getCurrentAccounts(clinicId) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.get(`/clinic/banks/${clinicId}`);
      dispatch(loginSlice.actions.currentAccountSuccess(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
      return { error: "Não foi possível obter as contas!" };
    }
  };
}

export function newCurrentAccount(data) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.post(`/clinic/banks/`, data);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function updateCurrentAccount(data) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.put(`/clinic/banks/${data.id}`, data);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function deletCurrentAccount(id) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.delete(`/clinic/banks/${id}`);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function getCostCenters(clinicId) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.get(`/clinic/center-cost/${clinicId}`);
      dispatch(loginSlice.actions.costCentersSuccess(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
      return { error: "Não foi possível obter os centros de custos!" };
    }
  };
}

export function newCostCenter(data) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.post(`/clinic/center-cost/`, data);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function updateCostCenter(data) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.put(`/clinic/center-cost/${data.id}`, data);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function deletCostCenter(id) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.delete(`/clinic/center-cost/${id}`);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function getFinanceCategories(clinicId) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.get(
        `/clinic/${clinicId}/financial-category`
      );
      dispatch(loginSlice.actions.financeCategoriesSuccess(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function newFinanceCategory(data) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.post(`/clinic/financial-category`, data);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function newFinanceSubCategory(data) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.post(
        `/clinic/financial-category/subcategory/${data.primaryId}`,
        data
      );
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function newFinanceSubSubCategory(data) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.post(
        `/clinic/financial-category/${data.primaryId}/subcategory/${data.secondaryId}`,
        data
      );
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function updateFinanceCategory(data) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.put(
        `/clinic/financial-category/${data.primaryId}`,
        data
      );
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function updateFinanceSubCategory(data) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.put(
        `/clinic/financial-category/subcategory/${data.primaryId}/${data.secondaryId}`,
        data
      );
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function updateFinanceSubSubCategory(data) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.put(
        `/clinic/financial-category/${data.primaryId}/subcategory/${data.secondaryId}/${data.thirdId}`,
        data
      );
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function deletFinanceCategory(primaryId) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.delete(
        `/clinic/financial-category/${primaryId}`
      );
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function deletFinanceSubCategory(primaryId, secondaryId) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.delete(
        `/clinic/financial-category/subcategory/${primaryId}/${secondaryId}`
      );
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function deletFinanceSubSubCategory(primaryId, secondaryId, thirdId) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.delete(
        `/clinic/financial-category/${primaryId}/subcategory/${secondaryId}/${thirdId}`
      );
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function getPaymentMethods(clinicId) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.get(`/clinic/payment-method/${clinicId}`);
      dispatch(loginSlice.actions.paymentMethodsSuccess(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
      return { error: "Não foi possível obter os métodos de pagamento!" };
    }
  };
}

export function updatePaymentMethods(data) {
  return async () => {
    try {
      const response = await axios.post(`/clinic/payment-method/`, data);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function updatePaymentMethodsLoading(data) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.post(`/clinic/payment-method/`, data);
      dispatch(loginSlice.actions.stopLoading());
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function getAllProfessionals(data) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.get(`/professional/clinic/${data}`);
      dispatch(loginSlice.actions.allProfessionalSuccess(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function getTransferRules(clinicId, professionalId) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      const response = await axios.get(
        `/clinic/transfer-rules/${clinicId}/${professionalId}`
      );
      const response2 = await axios.get(
        `/professional/procedures/${professionalId}`
      );
      dispatch(loginSlice.actions.proceduresSuccess(response2.data));
      dispatch(
        loginSlice.actions.transferRulesSuccess(
          response.data === ''
            ? {
              clinic: clinicId,
              professional: professionalId,
              byProcedure: false,
              value: 75,
              procedureRules: [],
            }
            : response.data
        )
      );
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function updateTransferRules(clinicId, professionalId, data) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      if (data.id) {
        return (
          await axios.put(
            `/clinic/transfer-rules/${clinicId}/${professionalId}`,
            data
          )
        ).data;
      }

      return (await axios.post(`/clinic/transfer-rules`, data)).data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function getAccessTokenReufyPayToClinic(clinicId, code) {
  return async () => {
    try {
      dispatch(loginSlice.actions.startLoading());
      let updateAccessTokenClinic;
      if (clinicId && code) {
        updateAccessTokenClinic = await axios.post(`/reufy-pay/access-token`, {
          clinicId,
          code,
        });
      }
      const responseGetNewClinic = await axios.get(`/clinic/${clinicId}`);
      dispatch(loginSlice.actions.getClinicSuccess(responseGetNewClinic.data));
  
      dispatch(setActive(6));
      dispatch(loginSlice.actions.stopLoading());
      return updateAccessTokenClinic;
    } catch (e) {
      console.error(e);
      dispatch(loginSlice.actions.stopLoading());
    }
  };
}
