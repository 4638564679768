import React from 'react';
import { AppBar, Box, Link, styled, Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ArrowBack, Close } from '@mui/icons-material';

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  boxShadow: 'none',
});

function Topbar() {
  const { pathname } = useLocation();
  return (
    <AppBar position="sticky" sx={{ boxShadow: 'none', bgcolor: 'white' }}>
      <StyledToolbar>
        {/* {pathname === '/sucesso' ? (
          <Link
            href="/"
            sx={{ position: 'absolute', left: theme => theme.spacing(3) }}>
            <Close color="disabled" />
          </Link>
        ) : null}
        {pathname !== '/perfil' && pathname !== '/sucesso' ? (
          <Link
            href="/"
            sx={{ position: 'absolute', left: theme => theme.spacing(3) }}>
            <ArrowBack color="disabled" />
          </Link>
        ) : null} */}
        <Box
          component="img"
          src="/assets/logo/logo-full.png"
          sx={{ height: 40 }}
        />
      </StyledToolbar>
    </AppBar>
  );
}

export default Topbar;
