import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '@components/Footer';
import Topbar from '@components/Topbar';

export default function MainLayout() {
  return (
    <>
      <Topbar />
      <Outlet />
      <Footer />
      <ToastContainer />
    </>
  );
}
