import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  resources: {
    items: [],
    meta: {},
  },
  selectedResource: null,
};

export const fetchResources = createAsyncThunk(
  'resources/fetchResources',
  async f => {
    const filters = {};
    Object.entries(f).forEach(([key, value]) => {
      if (value) {
        filters[key] = value;
      }
    });
    const response = await axios.get(`/resource`, {
      params: filters,
    });
    return response.data;
  }
);

export const saveResource = createAsyncThunk(
  'resources/saveResource',
  async resource => {
    const response = await axios.post(`/resource`, resource);
    return response.data;
  }
);

export const updateResource = createAsyncThunk(
  'resources/updateResource',
  async resource => {
    const response = await axios.put(`/resource/${resource?._id}`, resource);
    return response.data;
  }
);

export const deleteResource = createAsyncThunk(
  'resources/deleteResource',
  async id => {
    const response = await axios.delete(`/resource/${id}`);
    return { id, deleted: response.data.deleted };
  }
);

export const resourceSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    selectResource: (state, action) => {
      state.selectedResource = action.payload;
    },
    clearSelectedResource: state => {
      state.selectedResource = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchResources.pending, state => {
        state.loading = true;
      })

      .addCase(saveResource.pending, state => {
        state.loading = true;
      })
      .addCase(updateResource.pending, state => {
        state.loading = true;
      })
      .addCase(deleteResource.pending, state => {
        state.loading = true;
      })
      .addCase(fetchResources.fulfilled, (state, action) => {
        state.resources.items = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteResource.fulfilled, (state, action) => {
        if (action.payload.deleted === true) {
          const updatedItems = state.resources.items.filter(
            item => item._id !== action.payload.id
          );
          state.resources.items = updatedItems;
        }
      })
      .addCase(fetchResources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(saveResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { selectResource, clearSelectedResource } = resourceSlice.actions;
export default resourceSlice.reducer;
