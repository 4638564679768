import { Suspense } from 'react';

import Loader from './Loader';

const Loadable = Component =>
  // eslint-disable-next-line func-names
  function (props) {
    return (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    );
  };

export default Loadable;
