import { Button } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

export const StopButton = styled(Button)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.error.light,
  color: theme.palette.error.main,
  '&:hover': {
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
}));
