import { createSlice } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
import { dispatch } from '../../store';

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  scheduleDeleted: null,
  selectedEventId: null,
  selectedRange: null,
  doctor: null,
  procedures: [],
  healthInsurances: [],
  blockedScheduleDeletedSuccess: null,
  blockedScheduleCreatedSuccess: null,
  nextPatientsOfDay: [],
  isOpenModal: false,
  saveProfessional: null,
  selectedSchedule: null,
  schedulesPatient: null,
  scheduleId: null,
  calendarFilter: { startCalendarFilter: null, endCalendarFilter: null },
};

const slice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    calendarFilterData(state, action) {
      state.calendarFilter.startCalendarFilter = action.payload.startCalendarFilter;
      state.calendarFilter.endCalendarFilter = action.payload.endCalendarFilter;
   },

   resetScheduleDeleted(state) {
    state.scheduleDeleted = null;
  },

    startLoading(state) {
      state.isLoading = true;
    },

    endLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getEventsSuccess(state, action) {
      state.isLoading = false;
      const events = action.payload;
      state.events = events;
    },

    scheduleByIdSuccess(state, action) {
      state.isLoading = false;
      state.selectedSchedule = action.payload;
    },

    scheduleDetailsSuccess(state, action) {
      state.isLoading = false;
      state.scheduleDetails = action.payload;
    },

    blockedDetailsSuccess(state, action) {
      state.isLoading = false;
      state.blockedDetails = action.payload;
    },

    blockedScheduleCreatedSuccess(state, action) {
      state.isLoading = false;
      state.blockedScheduleCreatedSuccess = action.payload;
    },

    blockedScheduleDeletedSuccess(state, action) {
      state.isLoading = false;
      state.blockedScheduleDeletedSuccess = action.payload;
    },

    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map(_event => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      state.scheduleDeleted = eventId;
      const deleteEvent = state.events.filter(event => event.id !== eventId);
      state.events = deleteEvent;
    },

    getEventDetailsSuccess(state, action) { },

    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    getPatientsSuccess(state, action) { },

    getHealthInsurancesByPatientIdSuccess(state, action) { },

    nextPatientsOfDaySuccess(state, action) {
      state.isLoading = false;
      state.nextPatientsOfDay = action.payload;
    },

    scheduleSuccess(state, action) {
      state.isLoading = false;
      state.schedule = action.payload;
    },

    setIsOpenModal(state, action) {
      state.isLoading = false;
      state.isOpenModal = action.payload;
    },
    saveProfessionalSuccess(state, action) {
      state.isLoading = false;
      state.saveProfessional = action.payload;
    },
    schedulesBypatientSuccess(state, action) {
      state.isLoading = false;
      state.schedulesPatient = action.payload;
    },
    setScheduleId(state, action) {
      state.scheduleId = action.payload;
    },
  },
});

export default slice.reducer;

export const { resetScheduleDeleted, selectEvent, setIsOpenModal, setScheduleId, calendarFilterData } = slice.actions;

export function createEvent(newEvent) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/calendar/events/new', newEvent);
      dispatch(slice.actions.createEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateEvent(eventId, updateEvent) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/calendar/events/update', {
        eventId,
        updateEvent,
      });
      dispatch(slice.actions.updateEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteEvent(eventId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/calendar/events/delete', { eventId });
      dispatch(slice.actions.deleteEventSuccess({ eventId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchCalendarViewData(info) {
  dispatch(calendarFilterData({ startCalendarFilter: info.start, endCalendarFilter: info.end }));
}

export function selectRange(start, end) {
  return async () => {
    dispatch(
      slice.actions.selectRange({ start: start.getTime(), end: end.getTime() })
    );
  };
}

export function getNextPatientsOfDay(clinicId, professionalId) {
  const url =
    professionalId
      ? `schedule/${clinicId}?professionalId=${professionalId}`
      : `schedule/${clinicId}`;

  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.get(url);
      dispatch(slice.actions.nextPatientsOfDaySuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCalendarByPeriod(clinicId, professionalId, start, end, all = false) {
      return new Promise((resolve, reject) => {
        dispatch(slice.actions.startLoading());

      const url = all
        ? `schedule/events/${clinicId}/${clinicId}?all=true&start=${start}&end=${end}`
        : `schedule/events/${clinicId}/${professionalId}?start=${start}&end=${end}`;

      axios.get(url)
        .then(response => {
          dispatch(slice.actions.getEventsSuccess(response.data));
          resolve(response.data);
        })
        .catch(error => {
          dispatch(slice.actions.hasError(error));
          reject(error);
        });
      });
};

export function getCalendarOfProfessional(clinicId, professionalId, all = false) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());

      const url = all
        ? `schedule/events/${clinicId}/${clinicId}?all=${all}`
        : `schedule/events/${clinicId}/${professionalId}`;

      const response = await axios.get(url);
      dispatch(slice.actions.getEventsSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getScheduleDetails(clinicId, professionalId, scheduleId) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.get(
        `schedule/scheduleId/${clinicId}/${professionalId}/${scheduleId}`
      );
      dispatch(slice.actions.scheduleDetailsSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBlockedDetails(blockedId) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.get(
        `schedule/blockedId/${blockedId}`
      );
      dispatch(slice.actions.blockedDetailsSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const deleteBlocked = async (blockedId) => {
  try {
    const response = await axios.delete(
      `schedule/blocked/${blockedId}/`
    );
    dispatch(slice.actions.blockedScheduleDeletedSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const deleteScheduleById = async scheduleId => {
  try {
    dispatch(slice.actions.startLoading());
    const response = await axios.delete(`schedule/${scheduleId}`);
    dispatch(slice.actions.deleteEventSuccess({ eventId: scheduleId }));
    dispatch(slice.actions.endLoading());
    return { success: response.data };
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return { error };
  }
};

export function saveProfessionalInSlice(professional) {
  return async () => {
    try {
      // const response = await axios.get(`schedule/${clinicId}/${professionalId}/${scheduleId}`);
      dispatch(slice.actions.saveProfessionalSuccess(professional));
      // return response.data
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function createSchedule(clinicId, professionalId, data) {
  data.clinicId = clinicId;
  data.professionalId = professionalId;
  data.reminderSent = false;

  try {
    dispatch(getNextPatientsOfDay(data.clinicId, professionalId));
    return await axios.post(`schedule/schedule/`, data);
  } catch (error) {
    if (error?.statusCode === 406 && error.message) {
      return error;
    }
  }
}

export function createBlockedSchedule(data) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.post(`schedule/blocked`, data);
      dispatch(slice.actions.scheduleSuccess(response.data));
      dispatch(slice.actions.blockedScheduleCreatedSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editScheduleById(scheduleId, data, professionalId) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.put(`schedule/${scheduleId}`, { schedule: data });

      dispatch(getScheduleDetails(data.clinicId, data.professionalId, scheduleId));
      dispatch(getCalendarOfProfessional(data.clinicId, data.professionalId));
      dispatch(getNextPatientsOfDay(data.clinicId, professionalId));
      dispatch(slice.actions.scheduleByIdSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { error: 'Não foi possível realizar o reagendamento!' };
    }
  };
}

export function editScheduleStatusById(
  clinicId,
  professionalId,
  scheduleId,
  status
) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.patch(
        `schedule/${clinicId}/${professionalId}/update-status/${scheduleId}/${status}`
      );
      dispatch(getNextPatientsOfDay(clinicId, professionalId));
      dispatch(slice.actions.scheduleByIdSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createSchedulePayment(
  clinicId,
  professionalId,
  scheduleId,
  data
) {
  return async () => {
    const dataCustom = data;
    dataCustom.reminderSent = false;
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.post(
        `schedule/${clinicId}/${professionalId}/payment/${scheduleId}`,
        dataCustom
      );
      dispatch(slice.actions.schedulePaymentSuccess(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function editSchedulePayment(
  clinicId,
  professionalId,
  scheduleId,
  data
) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.put(
        `schedule/${clinicId}/${professionalId}/payment/${scheduleId}`,
        data
      );
      dispatch(slice.actions.schedulePaymentSuccess(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function getReceiptOfPayment(scheduleId) {
  return async dispatch => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.get(`pdf/receipt/${scheduleId}`, {
        responseType: 'arraybuffer',
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'ReufyRecibo.pdf';
      link.click();

      dispatch(slice.actions.endLoading());
      return response.data;
    } catch (e) {
      console.error(e);
      dispatch(slice.actions.endLoading());
    }
  };
}

export const getSchedulesByPatientId = async patientId => {
  try {
    dispatch(slice.actions.startLoading());
    const response = await axios.get(`schedule/${patientId}`);
    dispatch(slice.actions.schedulesBypatientSuccess(response.data));
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const getTimesAvailableByProcedureId = async (
  procedureId,
  startDate,
  finalDate
) => {
  try {
    dispatch(slice.actions.startLoading());
    const response = await axios.get(
      `schedule/procedureId/${procedureId}/startDate/${startDate}/finalDate/${finalDate}`
    );
    dispatch(slice.actions.endLoading());
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
