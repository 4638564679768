import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  categories: {
    items: [],
    meta: {},
  },
  selectedWorkloadCategory: null,
};

export const fetchWorkloadCategories = createAsyncThunk(
  'activity-category/fetchCategories',
  async f => {
    const filters = {};
    Object.entries(f).forEach(([key, value]) => {
      if (value) {
        filters[key] = value;
      }
    });
    const response = await axios.get(`/activity-category`, { params: filters });
    return response.data;
  }
);

export const saveWorkloadCategory = createAsyncThunk(
  'activity-category/saveCategory',
  async category => {
    const response = await axios.post(`/activity-category`, category);
    return response.data;
  }
);

export const updateWorkloadCategory = createAsyncThunk(
  'categories/updateWorkloadCategory',
  async category => {
    const response = await axios.put(
      `/activity-category/${category?._id}`,
      category
    );
    return response.data;
  }
);

export const deleteWorkloadCategory = createAsyncThunk(
  'categories/deleteWorkloadCategory',
  async id => {
    const response = await axios.delete(`/activity-category/${id}`);
    return { id, deleted: response.data.deleted };
  }
);

export const workloadCategorySlice = createSlice({
  name: 'workloadCategory',
  initialState,
  reducers: {
    selectWorkloadCategory: (state, action) => {
      state.selectedWorkloadCategory = action.payload;
    },
    clearSelectedCategory: state => {
      state.selectedWorkloadCategory = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchWorkloadCategories.pending, state => {
        state.loading = true;
      })
      .addCase(saveWorkloadCategory.pending, state => {
        state.loading = true;
      })
      .addCase(updateWorkloadCategory.pending, state => {
        state.loading = true;
      })
      .addCase(deleteWorkloadCategory.pending, state => {
        state.loading = true;
      })
      .addCase(fetchWorkloadCategories.fulfilled, (state, action) => {
        state.categories.items = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(saveWorkloadCategory.fulfilled, (state, action) => {
        state.categories.items.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateWorkloadCategory.fulfilled, (state, action) => {
        const index = state.categories.items.findIndex(
          category => category._id === action.payload._id
        );
        if (index !== -1) {
          state.categories.items[index] = action.payload;
        }
      })
      .addCase(deleteWorkloadCategory.fulfilled, (state, action) => {
        if (action.payload.deleted === true) {
          const updatedItems = state.categories.items.filter(
            item => item._id !== action.payload.id
          );
          state.categories.items = updatedItems;
        }
      })
      .addCase(fetchWorkloadCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(saveWorkloadCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateWorkloadCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteWorkloadCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { selectWorkloadCategory, clearSelectedCategory } =
  workloadCategorySlice.actions;
export default workloadCategorySlice.reducer;
