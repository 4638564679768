import { Box, Backdrop, Typography, Stack } from '@mui/material';

function LoadingOverlay({ isOpen, text }) {
  return (
    <Backdrop
      sx={{ color: 'white', zIndex: theme => theme.zIndex.tooltip + 1 }}
      open={isOpen}>
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Box
          component="div"
          // sx={{ display: 'flex', flexDirection: 'column' }}
          gap={1}>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Box component="img" src="/assets/icons/loading-icon.svg" />
            <Typography
              variant="h6"
              sx={{
                color: 'white',
                alignContent: 'center',
                alignSelf: 'center',
                alignItems: 'center',
              }}>
              {text}
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Backdrop>
  );
}

export default LoadingOverlay;
