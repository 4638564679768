import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  resourceCategories: {
    items: [],
    meta: {},
  },
  selectedResourceCategory: null,
};

export const fetchResourceCategories = createAsyncThunk(
  'resourceCategories/fetchResourceCategories',
  async f => {
    const filters = {};
    Object.entries(f).forEach(([key, value]) => {
      if (value) {
        filters[key] = value;
      }
    });
    const response = await axios.get(`/resource-categorie`, {
      params: filters,
    });
    return response.data;
  }
);

export const saveResourceCategory = createAsyncThunk(
  'resourceCategories/saveResourceCategory',
  async resourceCategory => {
    const response = await axios.post(`/resource-categorie`, resourceCategory);
    return response.data;
  }
);

export const updateResourceCategory = createAsyncThunk(
  'resourceCategories/updateResourceCategory',
  async resourceCategory => {
    const response = await axios.put(
      `/resource-categorie/${resourceCategory?._id}`,
      resourceCategory
    );
    return response.data;
  }
);

export const deleteResourceCategory = createAsyncThunk(
  'resourceCategories/deleteResourceCategory',
  async id => {
    const response = await axios.delete(`/resource-categorie/${id}`);
    return { id, deleted: response.data.deleted };
  }
);

export const resourceCategorySlice = createSlice({
  name: 'resourceCategories',
  initialState,
  reducers: {
    selectResourceCategory: (state, action) => {
      state.selectedResourceCategory = action.payload;
    },
    clearSelectedResourceCategory: state => {
      state.selectedResourceCategory = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchResourceCategories.pending, state => {
        state.loading = true;
      })

      .addCase(saveResourceCategory.pending, state => {
        state.loading = true;
      })
      .addCase(updateResourceCategory.pending, state => {
        state.loading = true;
      })
      .addCase(deleteResourceCategory.pending, state => {
        state.loading = true;
      })
      .addCase(fetchResourceCategories.fulfilled, (state, action) => {
        state.resourceCategories.items = action.payload;
        state.isLoading = false;
        state.error = null;
      })

      .addCase(saveResourceCategory.fulfilled, (state, action) => {
        state.resourceCategories.items.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateResourceCategory.fulfilled, (state, action) => {
        const index = state.resourceCategories.items.findIndex(
          resourceCategory => resourceCategory._id === action.payload._id
        );
        if (index !== -1) {
          state.resourceCategories.items[index] = action.payload;
        }
      })
      .addCase(deleteResourceCategory.fulfilled, (state, action) => {
        if (action.payload.deleted === true) {
          const updatedItems = state.resourceCategories.items.filter(
            item => item._id !== action.payload.id
          );
          state.resourceCategories.items = updatedItems;
        }
      })
      .addCase(fetchResourceCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(saveResourceCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateResourceCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteResourceCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { selectResourceCategory, clearSelectedResourceCategory } =
  resourceCategorySlice.actions;
export default resourceCategorySlice.reducer;
