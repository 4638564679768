import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  workloads: [],
  selectedWorkload: null,
  professionalWorkloads: null,
  professionalTypes: null,
  amountNoCompute: '',
  amountComputeHours: '',
  amountHours: '',
};
export const fetchWorkloads = createAsyncThunk(
  'professional-activity',
  async filters => {
    const response = await axios.get(`/professional-activity`, {
      params: filters,
    });
    return response.data;
  }
);

export const deleteWorkload = createAsyncThunk(
  'professional-activity/delete',
  async id => {
    const response = await axios.delete(`/professional-activity/${id}`);
    return response;
  }
);

export const fetchProfessionalWorkloads = createAsyncThunk(
  'professional-activity/workload/professional',
  async filters => {
    const response = await axios.get(
      `/professional-activity/workload/professional`,
      {
        params: filters,
      }
    );
    return response.data;
  }
);

export const fetchClinicProfessionalWorkloads = createAsyncThunk(
  'professional-activity/workload/professional',
  async filters => {
    const response = await axios.get(
      `/professional-activity/workload/professional`,
      {
        params: filters,
      }
    );
    return response.data;
  }
);

export const fetchProfessionalTypes = createAsyncThunk(
  'professional/types',
  async clinicId => {
    const response = await axios.get(`/professional/clinic/${clinicId}/type`);
    return response.data;
  }
);

export const saveWorkload = createAsyncThunk(
  'workload/saveWorkload',
  async professionalActivity => {
    const response = await axios.post(
      `/professional-activity`,
      professionalActivity
    );
    return response.data;
  }
);

export const addFile = createAsyncThunk('workload/addFile', async data => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await axios.postForm(`/upload/upload`, data, config);

  return response.data;
});

export const updateWorkload = createAsyncThunk(
  'workload/updateWorkload',
  async professionalActivity => {
    const response = await axios.put(
      `/professional-activity/${professionalActivity._id}`,
      professionalActivity
    );
    return response.data;
  }
);

export const workloadSlice = createSlice({
  name: 'workload',
  initialState,
  reducers: {
    selectWorkloadCategory: (state, action) => {
      state.selectedWorkloadCategory = action.payload;
    },
    clearSelectedCategory: state => {
      state.selectedWorkloadCategory = null;
    },
    selectWorkload: (state, action) => {
      state.selectedWorkload = action.payload;
    },
    clearSelectedWorkload: state => {
      state.selectedWorkload = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchWorkloads.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchProfessionalWorkloads.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchProfessionalTypes.pending, state => {
        state.isLoading = true;
      })
      .addCase(saveWorkload.pending, state => {
        state.isLoading = true;
      })
      .addCase(addFile.pending, state => {
        state.isLoading = true;
      })
      .addCase(updateWorkload.pending, state => {
        state.isLoading = true;
      })
      .addCase(deleteWorkload.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchWorkloads.fulfilled, (state, action) => {
        state.workloads = action.payload.professionalActivitys;

        state.amountComputeHours = action.payload.amountComputeHours
          ? Number(action.payload.amountComputeHours)
          : 0;

        state.amountNoCompute = action.payload.amount
          ? Number(action.payload.amount)
          : 0;

        state.amountHours = state.amountNoCompute + state.amountComputeHours;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchProfessionalWorkloads.fulfilled, (state, action) => {
        state.professionalWorkloads = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchProfessionalTypes.fulfilled, (state, action) => {
        state.professionalTypes = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(saveWorkload.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateWorkload.fulfilled, (state, action) => {
        const index = state.workloads.findIndex(
          contract => contract._id === action.payload._id
        );
        if (index !== -1) {
          state.workloads[index] = action.payload;
        }
      })
      .addCase(deleteWorkload.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchWorkloads.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchProfessionalWorkloads.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchProfessionalTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(saveWorkload.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateWorkload.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteWorkload.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  selectWorkloadCategory,
  clearSelectedCategory,
  selectWorkload,
  clearSelectedWorkload,
} = workloadSlice.actions;
export default workloadSlice.reducer;
