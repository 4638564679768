import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../../redux/store';
import axios from '../../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  clinicProfile: null,
};

const clinicProfileSlice = createSlice({
  name: 'clinicProfileSlice',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getClinicSuccess(state, action) {
      state.isLoading = false;
      state.clinicProfile = action.payload;
    },

    getHealthInsuranceSuccess(state, action) {
      state.isLoading = false;
      state.healthInsurance = action.payload;
    },

    getPaymentMethodSuccess(state, action) {
      state.isLoading = false;
      state.paymmentMethod = action.payload;
    },
  },
});

export default clinicProfileSlice;

export const { selectEvent } = clinicProfileSlice.actions;

export const getClinicProfile = async friendlyUrl => {
  try {
    dispatch(clinicProfileSlice.actions.startLoading());
    const response = await axios.get(`/public/clinic/${friendlyUrl}`);
    dispatch(clinicProfileSlice.actions.getClinicSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(clinicProfileSlice.actions.hasError(error));
  }
};
