import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../../redux/store';
import axios from '../../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  professionals: [],
  selectedProfessional: null,
  hasProceduresByClinic: null,
  edit: null,
  procedures: [],
  experience: null,
  formations: null,
  upload: null,
  online: null,
  google: null,
};

const RegisterProfessionalSlice = createSlice({
  name: 'registerProfessionalSlice',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      console.error('has error: ', action.payload);
      state.isLoading = false;
      state.error = action.payload;
    },

    getHasProcedures(state, action) {
      state.hasProceduresByClinic = action.payload;
      state.isLoading = false;
    },

    getAllSuccess(state, action) {
      state.isLoading = false;
      state.professionals = action.payload;
    },

    getProceduresSuccess(state, action) {
      state.isLoading = false;
      state.procedures = action.payload;
    },

    getProcedureSuccess(state, action) {
      state.isLoading = false;
      state.procedure = action.payload;
    },

    deleteProcedureSuccess(state, action) {
      state.isLoading = false;
      // state.procedures = action.payload;
      // state.selectedProfessional = action.payload[0]
    },

    selectProfessionalSuccess(state, action) {
      state.isLoading = false;
      state.selectedProfessional = action.payload;
    },
    editSuccess(state, action) {
      state.isLoading = false;
      state.experience = action.payload;
    },
    experienceSuccess(state, action) {
      state.isLoading = false;
      state.experience = action.payload;
    },
    formationsSuccess(state, action) {
      state.isLoading = false;
      state.formations = action.payload;
    },
    removeSelectedProfessionalSuccess(state, action) {
      state.isLoading = false;
      state.selectedProfessional = null;
    },
    uploadSuccess(state, action) {
      state.isLoading = false;
      state.upload = action.payload;
    },
    onlineSuccess(state, action) {
      state.isLoading = false;
      state.online = action.payload;
    },
    googleSuccess(state, action) {
      state.isLoading = false;
      state.google = action.payload;
    },
  },
});

export default RegisterProfessionalSlice;

export const { selectEvent } = RegisterProfessionalSlice.actions;

export const getAllProfessionals = async data => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const response = await axios.get(`/professional/clinic/${data}`);
    dispatch(RegisterProfessionalSlice.actions.getAllSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
  }
};

export const getProceduresByProfessional = async professionalId => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    if (professionalId === '#') {
      dispatch(RegisterProfessionalSlice.actions.getProceduresSuccess([]));
      return [];
    }
    const response = await axios.get(
      `/professional/procedures/${professionalId}`
    );
    dispatch(
      RegisterProfessionalSlice.actions.getProceduresSuccess(response.data)
    );
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
  }
};

export const getProcedureById = async procedureId => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const response = await axios.get(`/professional/procedure/${procedureId}`);
    dispatch(
      RegisterProfessionalSlice.actions.getProcedureSuccess(response.data)
    );
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
    return { error: "Não foi possível buscar o procedimento!" };
  }
};

export const saveNewProcedures = async (data) => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const response = await axios.post(`/professional/procedure`, data);
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
    return { error: "Não foi possível cadastrar o procedimento!" };
  }
};

export const editProcedure = async (data, procedureId) => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const response = await axios.put(`/professional/procedure/${procedureId}`, data);
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
    return { error: "Não foi possível editar o procedimento!" };
  }
};

export const deleteProcedure = async data => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const response = await axios.delete(`/professional/procedure/${data}`);
    dispatch(
      RegisterProfessionalSlice.actions.deleteProcedureSuccess(response.data)
    );
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
  }
};

export const selectProfessional = async data => {
  try {
    if (data) {
      dispatch(RegisterProfessionalSlice.actions.startLoading());
      const response = await axios.get(`/professional/${data}`);
      dispatch(
        RegisterProfessionalSlice.actions.selectProfessionalSuccess(response.data)
      );
      return response.data;
    }

    dispatch(
      RegisterProfessionalSlice.actions.selectProfessionalSuccess()
    );

    return null;

  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
  }
};

export const editProfessional = async (data, clinicId, professionalId) => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const body = {
      name: data.name,
      doc: data.doc,
      email: data.email,
      phone: data.phone,
      birthdate: new Date(data.birthdate).toISOString(),
      clinic: clinicId,
      gender: data.gender,
      status: 'active',
      professionalData: {
        profession: data.profession,
        council: data.council,
        register: data.register,
        rqe: data.rqe,
        state: data.state,
        treatment: data.treatment,
      },
      type: data.type,
      monthlyWorkload: data.monthlyWorkload,
    };

    const response = await axios.put(
      `/professional/update/${professionalId}/${clinicId}`,
      body
    );
    dispatch(RegisterProfessionalSlice.actions.editSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
    throw error;
  }
};

export const addNewExperience = async data => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const response = await axios.post(
      `/professional/online-info/experience/${data.id}`,
      { experience: data.experience }
    );
    dispatch(
      RegisterProfessionalSlice.actions.experienceSuccess(response.data)
    );
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
  }
};

export const deleteExperience = async (data, id) => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const helper = {
      experience: data,
    };
    const response = await axios.delete(
      `/professional/online-info/experience/${id}`,
      { data: helper }
    );
    await dispatch(
      RegisterProfessionalSlice.actions.experienceSuccess(response.data)
    );

    await dispatch(
      RegisterProfessionalSlice.actions.selectProfessionalSuccess(response.data)
    );

    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
  }
};

export const addNewFormation = async data => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const response = await axios.post(
      `/professional/online-info/formation/${data.id}`,
      { name: data.formations }
    );
    dispatch(
      RegisterProfessionalSlice.actions.formationsSuccess(response.data)
    );
    await dispatch(
      RegisterProfessionalSlice.actions.selectProfessionalSuccess(response.data)
    );
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
    return { error: 'Não foi possível adicionar a formação!' };
  }
};

export const deleteFormation = async (data, id) => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const response = await axios.delete(
      `/professional/online-info/formation/${id}/${data}`
    );
    dispatch(
      RegisterProfessionalSlice.actions.formationsSuccess(response.data)
    );

    await dispatch(
      RegisterProfessionalSlice.actions.selectProfessionalSuccess(response.data)
    );
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
  }
};

export const addNewSocialMedia = async (data, id) => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const response = await axios.post(
      `/professional/online-info/social-media/${id}`,
      data
    );
    dispatch(
      RegisterProfessionalSlice.actions.experienceSuccess(response.data)
    );
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
  }
};

export const removeSelectedProfessional = async () => {
  try {
    dispatch(
      RegisterProfessionalSlice.actions.removeSelectedProfessionalSuccess()
    );
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
  }
};

export const addNewImage = async data => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    // eslint-disable-next-line prefer-const
    let bodyFormData = new FormData();
    bodyFormData.append('file', data.file, data.file.path ?? data.file.name);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const response = await axios.postForm(
      `/upload/upload`,
      await bodyFormData,
      config
    );

    dispatch(RegisterProfessionalSlice.actions.uploadSuccess(response.data));
    return { success: true, data: response.data };
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
    return { success: false, error: error.message };
  }
};

export const addNewImageProfessionalORClinic = async data => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    // eslint-disable-next-line prefer-const
    let bodyFormData = new FormData();
    bodyFormData.append('file', data, data.path);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const response = await axios.postForm(
      `/upload/upload`,
      await bodyFormData,
      config
    );

    dispatch(RegisterProfessionalSlice.actions.uploadSuccess(response.data));
    return { success: true, data: response.data };
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
    return { success: false, error: error.message };
  }
};

export const saveImagesProfessional = async (data, id) => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const response = await axios.post(
      `/professional/online-info/media/${id}/false`,
      data
    );
    dispatch(RegisterProfessionalSlice.actions.uploadSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
  }
};

export const deleteImagesProfessional = async (data, id) => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const response = await axios.delete(
      `/professional/online-info/media/${id}/false`,
      { data }
    );
    dispatch(RegisterProfessionalSlice.actions.stopLoading());
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
  }
};

export const deleteVideosProfessional = async (data, id) => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const response = await axios.delete(
      `/professional/online-info/media/${id}/true`,
      { data }
    );
    dispatch(RegisterProfessionalSlice.actions.stopLoading());
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
  }
};

export const saveVideoProfessional = async (data, id) => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const response = await axios.post(
      `/professional/online-info/media/${id}/true`,
      data
    );
    dispatch(RegisterProfessionalSlice.actions.uploadSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
  }
};

export const saveProfessionalOnlineInfo = async (data, professional) => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const response = await axios.post(
      `/professional/online-info/${professional}`,
      data
    );
    dispatch(RegisterProfessionalSlice.actions.onlineSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
  }
};

export const saveGoogleToken = async (professional, token) => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const response = await axios.put(`/professional/${professional}/google`, {
      refreshToken: token,
    });
    dispatch(RegisterProfessionalSlice.actions.googleSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
  }
};

export const hasProceduresByClinic = async clinicId => {
  try {
    dispatch(RegisterProfessionalSlice.actions.startLoading());
    const response = await axios.get(`/professional/has-procedure/${clinicId}`);
    dispatch(RegisterProfessionalSlice.actions.getHasProcedures(response.data));
    return response.data;
  } catch (error) {
    dispatch(RegisterProfessionalSlice.actions.hasError(error));
  }
};
