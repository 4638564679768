import { Box, Divider, Typography, Chip } from '@mui/material';
import { scheduleStatus } from 'src/utils/scheduleStatus';
import { RoundBox } from '../atoms/RoundBox';

const ScheduleLegends = () => (
  <Box sx={{ marginBottom: '16px', padding: '16px', borderRadius: '8px' }}>
    <Box display="flex" flexDirection="column" p={2}>
      <Box component="div" textAlign="center">
        <Typography variant="overline" color="text.secondary" sx={{ ml: 2 }}>
          Status dos Agendamentos
        </Typography>
      </Box>
      <Divider />
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        mt={1}
        pb={3}
        gap={1}>
        {scheduleStatus.map(item => (
          <Box
            key={item.description}
            component="div"
            display="flex"
            alignItems="center"
            gap={1}>
            <RoundBox color={item.backgroundColor} borderColor={item.color} />
            <Typography variant="caption">{item.description}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  </Box>
);

export default ScheduleLegends;
